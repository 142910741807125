<template>
  <a-locale-provider :locale="zh_CN">
  <router-view/>
  </a-locale-provider>
</template>
<script>
  import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
  import moment from "moment";
  import "moment/locale/zh-cn";

  moment.locale("zh-cn");
  export default {
    name: "App",
    data() {
      return {
        zh_CN,
      };
    },
  };
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
