import { createApp } from 'vue'
import App from './App.vue'
import {init} from "@/util/init";
const { store, router } = init();
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import baseMixin from '@/util/globalAuth'
import * as Icons from "@ant-design/icons-vue";
import VueNativeSock from "vue-native-websocket-vue3";
const icons = Icons;
import store1 from "@/store";
import Vue3NativeNotification from 'vue3-native-notification'
const app = createApp(App);
app.use(Antd);
app.use(store);
app.use(router);
app.use(VueNativeSock,"wss://gdapi.agoodsys.com:2345",{
    store:store1,
    format: "json"
});
app.use(Vue3NativeNotification, {
    // Automatic permission request before
    // showing notification (default: true)
    requestOnNotify: true,
})
app.mixin(baseMixin);
app.mount("#app");
for (const i in icons) {
    app.component(i, icons[i]);
}
export default app;
