import http from './http'

export function index(data) {
    return http.post('/index/admin/index',data);
}
export function addUser(data) {
    return http.post('/index/admin/addUser',data);
}
export function userList(data) {
    return http.post('/index/admin/userList',data);
}
export function del(data) {
    return http.post('/index/admin/del',data);
}
export function getUser(data) {
    return http.post('/index/admin/getUser',data);
}
export function addRoute(data) {
    return http.post('/index/admin/addRoute',data);
}
export function routeList(data) {
    return http.post('/index/admin/routeList',data);
}
export function delRoute(data) {
    return http.post('/index/admin/delRoute',data);
}
export function addWork(data) {
    return http.post('/index/admin/addWork',data);
}
export function workList(data) {
    return http.post('/index/admin/workList',data);
}
export function delWork(data) {
    return http.post('/index/admin/delWork',data);
}
export function getWork(data) {
    return http.post('/index/admin/getWork',data);
}
export function getProute(data) {
    return http.post('/index/admin/getProute',data);
}
export function getRoute(data) {
    return http.post('/index/admin/getRoute',data);
}
export function getFullRoute(data) {
    return http.post('/index/admin/getFullRoute',data);
}
export function submitRole(data) {
    return http.post('/index/admin/submitRole',data);
}
export function roleList(data) {
    return http.post('/index/admin/roleList',data);
}
export function getRoleInfo(data) {
    return http.post('/index/admin/getRoleInfo',data);
}
export function delRole(data) {
    return http.post('/index/admin/delRole',data);
}
export function getRole(data) {
    return http.post('/index/admin/getRole',data);
}
export function getNavAuth(data) {
    return http.post('/index/admin/getNavAuth',data);
}
