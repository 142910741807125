import axios from 'axios'
import router from "@/router";
import baseUrl from "@/util/api"
import {message} from 'ant-design-vue'
import store from '@/store'
export function request(config) {
  // 创建axios的实例
  /*axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded;charset=UTF-8";*/
  const instance = axios.create({
    baseURL: baseUrl.apiUrl,
    timeout: 50000,
    //headers: "application/x-www-form-urlencoded;charset=UTF-8"
  })
  // 请求拦截器配置
  instance.interceptors.request.use(config => {
      //config.headers.Authorization = window.sessionStorage.getItem('token')
      const token = store.getters.token;
      //console.log(token);
      if (token) {
        config.headers.Authorization = token;
      }
      return config
    }, error => {
      console.log(error)
      return Promise.error(error)
    }
  )
  // 响应拦截器配置
  instance.interceptors.response.use(response => {
    if(response.data.status=="fail"){
      message.error(response.data.msg);
      router.push({path:'/login'});
      window.localStorage.clear();
    }else{
      return response.data
    }
  }, error => {
    return Promise.reject(error)
  })
  // 发送真正的网络请求
  return instance(config);
}

export default request
