import { computed ,watch} from "vue";
import store from "@/store";
import router from "@/router";
import {addData} from "@/util/nav"

export const init = () => {
    const isLogin = () => {
        return store.getters.token;
    };
    //console.log(isLogin());
    if (isLogin()) {
        //console.log(888888);
        addData();
    }
    return {
        store,
        router
    };
};
