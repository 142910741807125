import router from "../router";
import store from "@/store";
import {getNavAuth} from '@/network/admin';
import {isJSON} from "@/util/util";

export async function addData() {
    if(!store.getters.uid||!store.getters.nav){
        console.log(111);
        return
    }
    if(window.localStorage.getItem('nav')){
        console.log(222);
        let nav = isJSON(window.localStorage.getItem('nav'));
        //console.log(nav);
        nav.forEach(child=>{
            //console.log(4444);
            toRoute(child)
        })
        await store.dispatch("authinfo", window.localStorage.getItem('auth'));
        await store.dispatch("navinfo", JSON.parse(window.localStorage.getItem('nav')));
    }else {
        console.log(333);
        getNavAuth({'uid': store.getters.uid}).then(async (res) => {
            await store.dispatch("authinfo", res.auth);
            await store.dispatch("navinfo", res.nav);
            await res.nav.forEach(child=>{
                toRoute(child)
            })
        })
    }
}

//转为路由
function toRoute(child) {
    //console.log(4444);
    router.addRoute("Admin", {
        path: child.path,
        name: child.name,
        meta: {
            title: child.title,
        },
        component: () => import('@/components' + child.component)
    })
    //console.log(router.getRoutes());
}
