import store from '@/store'
const baseMixin = {
    methods: {
        hasAuth(perm){
            var auth = store.state.auth;
            //console.log(auth);
            return auth.indexOf(perm) >-1
        }
    }
}

export default baseMixin;
