import { App, InjectionKey } from "vue";
import { createStore, useStore as baseUseStore, Store } from "vuex";
import { TypeRootState } from "./type";
import main from "@/main";

const store = createStore<TypeRootState>({
  state: {
      token:window.localStorage.getItem('token'),
      uid:window.localStorage.getItem('uid'),
      uname:window.localStorage.getItem('uname'),
      nav:window.localStorage.getItem('nav'),
      auth:window.localStorage.getItem('auth'),
      socket: {
        // 连接状态
        isConnected: false,
        // 消息内容
        message: "",
        // 重新连接错误
        reconnectError: false,
        // 心跳消息发送时间
        heartBeatInterval: 50000,
        // 心跳定时器
        heartBeatTimer: 0
      },
  },
  getters:{
    token:state =>state.token,
    uid:state =>state.uid,
    uname:state =>state.uname,
    nav:state =>state.nav,
    auth:state =>state.auth,
  },
  mutations: {
    SETUID(state,data){
      state.uid = data;
      window.localStorage.setItem('uid', data);
    },
    SETTOKEN(state,data){
      state.token = data;
      window.localStorage.setItem('token', data);
    },
    SETUNAME(state,data){
      state.uname = data;
      window.localStorage.setItem('uname', data);
    },
    SETNAV(state,data){
      state.nav = data;
      window.localStorage.setItem('nav', JSON.stringify(data));
    },
    SETAUTH(state,data){
      state.auth = data;
      window.localStorage.setItem('auth', data);
    },
    SOCKET_ONOPEN(state, event) {
      main.config.globalProperties.$socket = event.currentTarget;
      state.socket.isConnected = true;
      // 连接成功时启动定时发送心跳消息，避免被服务器断开连接
      state.socket.heartBeatTimer = setInterval(() => {
        const message = "心跳消息";
        //console.log(main);
        state.socket.isConnected && main.config.globalProperties.$socket.sendObj({
          type: 'heart',
          msg: message
        });
      }, state.socket.heartBeatInterval);
    },
    // 连接关闭
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false;
      // 连接关闭时停掉心跳消息
      clearInterval(state.socket.heartBeatTimer);
      state.socket.heartBeatTimer = 0;
      console.log("连接已断开: " + new Date());
      console.log(event);
    },
    // 发生错误
    SOCKET_ONERROR(state, event) {
      console.error(state, event);
    },
    // 收到服务端发送的消息
    SOCKET_ONMESSAGE(state, message) {
      state.socket.message = message;
    },
    // 自动重连
    SOCKET_RECONNECT(state, count) {
      console.info("消息系统重连中...", state, count);
    },
    // 重连错误
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true;
    }
  },
  actions: {
    uidinfo({commit},data){
      commit('SETUID',data)
    },
    tokeninfo({commit},data){
      commit('SETTOKEN',data)
    },
    unameinfo({commit},data){
      commit('SETUNAME',data)
    },
    navinfo({commit},data){
      commit('SETNAV',data)
    },
    authinfo({commit},data){
      commit('SETAUTH',data)
    },
  },
  modules: {
  }
})
export default store;

// 定义 injection key
const key: InjectionKey<Store<TypeRootState>> = Symbol();
// 定义自己的 `useStore` 组合式函数
export function useStore() {
  return baseUseStore(key);
}

export const setupStore = (app: App) => {
  app.use(store, key);
};
