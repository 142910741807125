import {createRouter, createWebHashHistory, RouteRecordRaw, stringifyQuery} from 'vue-router'
import store from "@/store/index"
import {message} from 'ant-design-vue'
import {getNavAuth} from '@/network/admin'
const routes: Array<RouteRecordRaw> = [{
  path: '/',
  name: 'Admin',
  redirect: '/admin/index',
  component: () => import('../views/Admin.vue'),
  children: [
    {
      path: '/admin/index',
      name: 'Index',
      meta: {
        title: '添加产品',
      },
      component: () => import(/* webpackChunkName: "about" */ '../components/Index.vue')
    }
  ]
},
  {
    path: '/login',
    name: 'Login',
    //redirect: '/login',
    meta: {
      title: '管理系统登录页',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('../views/404.vue'),
  }
]
const createCustomRouter =()=> createRouter({
  history: createWebHashHistory(process.env.BASE_URL), //
  routes
})
const router = createCustomRouter();
router.beforeEach(async(to, from, next) => {
  if (store.state.token) {
    if (store.getters.nav&&store.getters.nav.length>0) {
      console.log(1);
      next();
    } else {
      console.log(2);
      await addData();
      next();
    }
  } else {
    if (to.path !== "/login") {
      //console.log('3');
      next({path: '/login'});
    } else {
      //console.log('4');
      next();
    }
  }
  if(typeof(to.meta.title)==='string'){
    document.title= to.meta.title
  }
})
function addData() {
  if (store.getters.nav) {
    return
  }
  getNavAuth({'uid': store.getters.uid}).then((res) => {
    store.dispatch("authinfo", res.auth);
    store.dispatch("navinfo", res.nav);
    res.nav.forEach(child=>{
      toRoute(child)
    })
  })
}

//转为路由
function toRoute(child) {
  console.log(666666);
  router.addRoute("Admin", {
    path: child.path,
    name: child.name,
    meta: {
      title: child.title,
    },
    component: () => import('@/components' + child.component)
  })
}
export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher
  //const newRouter = createCustomRouter()
  //console.log(router);
  //router.matcher = newRouter.matcher // reset router
}
export default router
