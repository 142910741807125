//let apiUrl = "http://4.com:8888";
let apiUrl = "https://gdapi.agoodsys.com";
//let apiUrl = "http://testapi.moonway.cn";

let baseUrl = {
   apiUrl
}

export default baseUrl

